import React, { Component } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

class Resume extends Component {
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;
      var education_length = this.props.data.education.length
      var education = this.props.data.education.map(function(education, i){
        return <div key={education.school}>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    {i + 1 !== education_length &&
                        <TimelineConnector />
                    }
                </TimelineSeparator>
                <TimelineContent>
                    <h3>{education.school}</h3>
                    <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
                    <p>{education.description}</p>
                </TimelineContent>
            </TimelineItem>
            </div>
      })

        var work = this.props.data.work.map(function(work){
        return <div key={work.company}>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <h3>{work.company}</h3>
                    {work.positions.map((position, i) => {
                        var r = []
                        r.push(<p key={position}></p>)
                        r.push(<span key={i} className="info">{position.title}</span>)
                        {position.years &&
                        r.push(<span><span>&bull;</span> <em className="date">{position.years}</em></span>)
                        }
                        position.description.map((description, j) => r.push(<p className="detail" key={description}>{description}</p>));
                        return r
                    })}
                    <p></p>
                </TimelineContent>
            </TimelineItem>
        </div>
      })
      var skills = this.props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
      })
      var certificates = this.props.data.certificates.map(function(certificate){
        var certificateImage = 'images/certificates/'+certificate.image;
        return <div key={certificate.title} className="columns feature-item">
          <a href={certificate.url}>
            <img className="certificate" alt={certificate.title} src={certificateImage}></img>
          </a>
          <h5>{certificate.title}</h5>
        </div>
      })
    }

    return (
      <section id="resume">

        <div className="row education">
          <div className="three columns header-col">
              <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                        }}
                    >
                    {education}
                    </Timeline>
                </div>
              </div>
          </div>
        </div>


        <div className="row work">

          <div className="three columns header-col">
              <h1><span>Work</span></h1>
          </div>

          <div className="nine columns main-col">
              <Timeline
                  sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                      },
                  }}
              >
            {work}
              </Timeline>

          </div>
        </div>



        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>

          <div className="nine columns main-col">
            <p>{skillmessage}</p>

				    <div className="bars">
				      <ul className="skills">
                {skills}
              </ul>
				    </div>  
			    </div>
        </div>

        <div className="row certificate">
        <div className="three columns header-col">
            <h1><span>Certificates</span></h1>
          </div>

          <ul className="bgrid-quarters s-bgrid-thirds cf">
            {certificates}
          </ul>
        </div>

      </section>
    );
  }
}

export default Resume;
