import React, { Component } from 'react';

class Header extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var occupation= this.props.data.occupation;
      // var position = this.props.data.position;
      // var description= this.props.data.description;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            <li><a className="smoothscroll" href="#about">About</a></li>
	         <li><a className="smoothscroll" href="#resume">Resume</a></li>
            <li><a className="smoothscroll" href="#portfolio">Works</a></li>
            <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
            <li><a className="smoothscroll" href="#contact">Contact</a></li>
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">I'm {name}.</h1>
            <h3>
                <span>A bilingual man, Japanese and English</span>, who grew up in <span>Japan until high school</span> and then went to <span>University in the U.S</span> to study <span>Computer Science</span>. After my academic completion, <span>worked</span> in two startups and one tech company <span>in the U.S and Japan.</span>
                <br></br>
                <br></br>
                I’ve been learning not only <span>engineering skills</span> but also the <span>importance of connectivity / communication</span> between people. That is <span>the power of the team.</span>
                <br></br>
                <br></br>
                After much contemplation of the nature of my existence, <span>my vision and goal</span> have changed to <span>going to space.</span> Since then I always look how the current action or experience will lead to that future. I'm looking to going to space, <span>Mars.</span>
            </h3>
            <hr />
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
